
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import ClaimCard from "@/views/claim/ClaimCard.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";

export default defineComponent({
  name: "Claims",
  components: {Cards, ClaimCard},
  props: {
    cazeId: {type: String}
  },
  setup(props) {
    const filterObject = ref({cazeId: props.cazeId})
    const claimPage = computed(() => store.state.CaseModule.claimPage);
    return {
      ...LoadFilterObjects(Actions.LOAD_CASE_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner']),
      claimPage,
    }
  }
})
